@import "styles/mixins.scss";

.root {
  width: 100%;

  .title {
    @include anyText($secondary_color, 700, 20px, 30px);
    margin-bottom: 15px;
    margin-left: 15px;
    margin-top: 30px;
  }

  .body {
    display: flex;
    align-items: center;
    column-gap: 28px;
    row-gap: 28px;
    flex-wrap: wrap;
  }
}
