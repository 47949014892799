@import "styles/index.scss";

.root {
  width: 20px;
  height: auto;
  fill: $secondary_color;

  &.selected {
    fill: #fff;

    path {
      fill: #fff;
    }
  }
}
