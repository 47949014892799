@import "styles/index.scss";

.sidebar-item-subnav {
  text-decoration: none;
  &.active {
    span {
      color: $third_color;
      text-decoration: underline;
    }
  }

  &.hidden {
    display: none;
  }
}
