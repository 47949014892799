.root {
  display: flex;
  flex-direction: column;
  width: 100%;
  row-gap: 10px;
  margin-top: 35px;
  max-width: 200px;
  overflow-y: auto;
  max-height: calc(100vh - 176px);
}

@media screen and (max-width: 1280px) {
  .root {
    row-gap: 5px;
  }
}
