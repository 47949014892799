@import "styles/index.scss";
@import "styles/mixins.scss";

.root {
  max-width: 1050px;
  width: 100%;
  @include box;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px 20px;
  box-sizing: border-box;
}
.input {
  max-width: 420px;
  width: 100%;
  height: 50px;
  padding: 18px 24px;
  background: #ffffff;
  border: 1px solid #efefef;
  border-radius: 16px;
  display: block;
  font-weight: 400;
  font-size: 14px;
  line-height: 100%;
  color: #000;
  box-sizing: border-box;

  &::placeholder {
    font-weight: 400;
    font-size: 14px;
    line-height: 100%;
    color: $secondary_color;
  }

  &.error {
    border-color: $error_color;
    color: $error_color;

    &::placeholder {
      color: $error_color;
    }
  }
}

.inputContainer {
  position: relative;
}

.err {
  position: absolute;
  color: $error_color;
  bottom: -10px;
}

.content {
  display: flex;
  align-items: center;
  justify-content: center;
  // flex-wrap: wrap;
  column-gap: 30px;
  row-gap: 30px;
  width: 100%;
}

.leftSide,
.rightSide {
  column-gap: 30px;
  row-gap: 30px;
  display: flex;
  flex-direction: column;
  max-width: 420px;
  width: 100%;
  padding-bottom: 30px;
  box-sizing: border-box;
}

.btn {
  @include button;
  width: 420px;
  height: 50px;
  background: $third_color;
  font-weight: 600;
  font-size: 20px;
  border-radius: 16px;
}

.avatarContainer {
  position: relative;
  margin-bottom: 30px;

  &:hover {
    .deleteIcon {
      display: block;
    }
  }
}

.avatar {
  width: 146px;
  height: 146px;
  font-size: 45px;
}

.editIcon {
  width: 32px;
  height: 32px;
  position: absolute;
  bottom: 5px;
  right: 10px;
}

.label {
  font-weight: 400;
  font-size: 13px;
  line-height: 100%;
  color: $secondary_color;
  opacity: 1;
  transition: all 0.3s ease;
  position: absolute;
  padding: 0 5px;
  background: #fff;
  top: -8px;
  left: 20px;

  &.hidden {
    opacity: 0;
  }
}

@media screen and (max-width: 1000px) {
  .content {
    flex-wrap: wrap;
  }
}

.deleteIcon {
  width: 20px;
  height: 20px;
  position: absolute;
  background: rgb(63, 31, 237);
  padding: 5px;
  fill: white;
  border-radius: 50%;
  right: 10px;
  display: none;
  cursor: pointer;
}
