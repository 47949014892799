@import "styles/mixins.scss";

.root {
  width: 240px;
  height: 90px;
  background: #fff;
  border: 1px solid #fff;
  border-radius: 15px;
  transition: all 0.3s ease;
  padding: 20px;
  box-sizing: border-box;
  cursor: pointer;

  &.selected {
    border: 1px solid #8bba05;
    transition: all 0.3s ease;
  }

  &.disabled {
    opacity: 0.8;
    cursor: not-allowed;
  }

  &:hover {
    border-color: $secondary_color;
    transition: all 0.3s ease;
  }

  .title {
    @include anyText(#000, 600, 20px, 24px);
    @include text-overflow;
    margin-bottom: 6px;
  }

  .message {
    @include anyText(#000, 500, 16px, 19px);

    &.primary {
      color: $blue_color;
    }

    &.success {
      color: $third_color;
    }

    &.danger {
      color: $error_color;
    }
  }
}
