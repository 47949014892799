@import "styles/index.scss";

.root {
  label {
    color: $secondary_color !important;
  }
  &.selected {
    background: $third_color;

    span {
      color: white;
    }

    svg {
      fill: white;
    }
  }
}

.label {
  color: $secondary_color;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-all;

  &::first-letter {
    text-transform: uppercase;
  }

  &.selected {
    color: #fff;
  }

  &.longtermPrices {
    word-break: break-word;
    white-space: pre-line;
  }
}

.childContent {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  padding: 24px 0;
  box-sizing: border-box;
  height: auto;
  transition: all 0.4s ease;
  padding-left: 70px;

  &.hidden {
    height: 0;
    overflow: hidden;
    padding: 0;
    transition: all 0.3s ease;
  }
}

.navItemContainer {
  &.active {
    display: flex;
    justify-content: center;
  }
}
