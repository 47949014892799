@import "styles/mixins.scss";

.root {
  margin: 6px 0 45px;

  .header {
    display: grid;
    grid-template-columns: 31.42% 1fr;
    grid-gap: 2.86%;

    margin-bottom: 20px;

    label {
      @include anyText($secondary_color, 500, 17px, 30px);
    }

    & > div {
      display: grid;
      grid-template-columns: 47.82% 1fr;
      grid-gap: 4.34%;

      .dateContainer {
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }

      .priceContainer {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }

    .btn {
      @include app_button;
    }
  }
}
