@import "styles/mixins.scss";

.root {
  display: grid;
  grid-template-columns: 117px 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
}
.item {
  width: 100%;
  border-right: 1px solid $table_border_color;
  box-sizing: border-box;
  background: #fff;

  &.first {
    border-bottom-left-radius: 15px;
    border-top-left-radius: 15px;
  }

  &.last {
    border-bottom-right-radius: 15px;
    border-top-right-radius: 15px;
    border-right: none;
  }

  .head {
    min-height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    @include anyText($secondary_color, 400, 14px, 21px);
    text-align: center;
    border-bottom: 1px solid $table_border_color;
  }

  .value {
    padding: 8px 7px 4px 8px;
    box-sizing: border-box;
  }
}
