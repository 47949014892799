@import "styles/mixins.scss";

.root {
  padding: 30px 20px 20px 20px;
  box-sizing: border-box;
  max-width: 330px;
  width: 100%;
  min-height: 434px;
  @include box;

  .title {
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: #000000;
    margin-bottom: 12px;
  }

  .locationContainer {
    margin-top: 8px;
    display: flex;
    align-items: center;
    column-gap: 35px;
  }

  .outputProfile {
    margin-top: 14px;
  }
}
.dropdown {
  margin-top: 14px;
}

.ratio {
  margin-top: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ratioInput {
  @include material-input;
  width: 100%;
  max-width: 145px;
  margin-left: 5px;
}

.sum {
  margin-top: 8px;

  span {
    padding: 0 9px;
  }

  label {
    line-height: 18px;
  }
}

.btn {
  @include button;
  width: 290px;
  height: 50px;
  background-color: $third_color;
  margin-top: 8px;
}

@media screen and (max-height: 800px) {
  .root {
    min-height: auto;
    row-gap: 10px;
    padding: 10px;
    width: auto;

    .title,
    .dropdown,
    .locationContainer,
    .ratio {
      margin-bottom: 10px;
    }
  }
}
