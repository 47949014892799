.root {
  .skeletonContainer {
    margin-bottom: 45px;

    .yearPicker {
      height: 280px;
      margin-top: 110px;
      background-color: white;
      margin-bottom: 30px;
      border-radius: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .hedge {
      height: 454px;
      background-color: white;
      border-radius: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
