@import "styles/index.scss";
@import "styles/mixins.scss";

.root {
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  padding-bottom: $site_margin_bottom;
}

.corridor {
  width: 100%;
  display: flex;
  align-items: center;
  column-gap: 30px;
  display: grid;
  grid-template-columns: 1fr 330px;
}

.chart {
  position: relative;
  @include box;
  height: 323px;

  .loader {
    position: absolute;
    left: calc(50% - 40px);
    top: calc(50% - 40px);
  }
}
