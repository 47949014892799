@import "styles/index.scss";

.root {
  position: relative;
}

.modal-content {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: $secondary_color;
}
