.authContainer,
.loaderContainer {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loaderContainer {
  position: absolute;
  left: 0;
  top: 0;
}
