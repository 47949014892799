@import "styles/index.scss";
@import "styles/mixins.scss";

.root {
  width: calc(100% - 342px);
  min-height: 434px;
  height: 100%;
  @include box;
  padding: 30px;
  box-sizing: border-box;

  .dropzone {
    background: #ffffff;
    border: 1px dashed $third_color;
    border-radius: 15px;
    width: 100%;
    min-height: 370px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;

    &.files {
      align-items: baseline;
      justify-content: flex-start;
      padding: 30px;
      box-sizing: border-box;
    }

    &.active {
      border: 1px solid $blue_color;

      .icon {
        transition: all 0.3s ease-in;
        fill: $third_color;
      }
    }
  }
}

.icon {
  transition: all 0.3s ease-in;
  width: 100px;
  height: 100px;
  fill: $secondary_color;
  margin-bottom: 8px;
}

.title,
.subtitle {
  color: $secondary_color;
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
}

.subtitle {
  font-size: 16px;
  line-height: 20px;
  margin-top: 10px;
}

.uploadFiles {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  column-gap: 30px;
  row-gap: 30px;
}

.btn {
  @include button;
  background-color: $third_color;
  max-width: 420px;
  width: 100%;
  height: 50px;
  margin-top: 40px;
}

.clearBtn {
  display: flex;
  align-items: center;
  column-gap: 4px;
  @include invisible-button;
  position: absolute;
  bottom: 18px;
  right: 15px;
  cursor: pointer;

  &:hover {
    .text {
      color: $third_color;
    }

    .clearIcon {
      fill: $third_color;
    }
  }

  .clearIcon {
    width: 20px;
    height: 20px;
    fill: $secondary_color;
  }
  .text {
    color: $secondary_color;
    font-weight: 400;
    font-size: 14px;
    line-height: 1;
  }
}

@media screen and (max-height: 800px) {
  .root {
    min-height: 300px;
    max-height: 394px;
    padding: 10px;

    .dropzone {
      height: 371px;
      min-height: auto;
    }
  }
}
