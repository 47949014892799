.btn {
  border: none;
  background: #fff;
  outline: none;

  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
}
