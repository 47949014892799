@import "styles/mixins.scss";

.root {
  display: flex;
  flex-direction: column;
  row-gap: 30px;

  .head {
    @include anyText(#000, 700, 24px, 30px);
    max-width: calc(100% - 140px);
    @include text-overflow;
    margin-top: 6px;
  }

  .content {
    height: calc(100vh - 220px);
    overflow-y: auto;
    @include scrollbar;
    display: flex;
    flex-direction: column;
    row-gap: 30px;
  }
}

@media screen and (max-height: 800px) {
  .root {
    row-gap: 10px;
    .head {
      margin-top: 10px;
      font-size: 26px;
      line-height: 35px;
    }

    .content {
      height: calc(100vh - 130px);
    }
  }
}
