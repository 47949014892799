@import "styles/mixins.scss";
@import "styles/index.scss";

.root {
  width: 330px;
  height: auto;
  padding: 30px 20px;
  box-sizing: border-box;
  flex-shrink: 0;
  @include box;

  .title {
    @include title;
    margin-bottom: 20px;
  }

  .text {
    margin-top: 15px;
    @include anyText($secondary_color, 400, 16px, 20px);
  }
}

@media screen and (max-height: 800px) {
  .root {
    padding: 10px;
  }
}
