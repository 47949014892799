@import "styles/mixins.scss";

.root {
  display: flex;
  flex-direction: column;
  width: 100%;
  color: #4d4d4d;

  &.error {
    .input {
      color: $error_color !important;
      border-color: $error_color;

      &:-internal-autofill-selected {
        color: $error_color !important;
      }
    }
  }

  .input {
    @include input;
    width: 100%;
    max-height: 50px;
  }

  .alert {
    color: red;
  }

  .btn {
    cursor: pointer;
    @include button;
    height: 50px;
    font-weight: 600;
  }

  .inputContainer {
    position: relative;
    margin-bottom: 30px;

    .icon {
      position: absolute;
      top: 16px;
      right: 20px;
      fill: #df2d2d;
      width: 20px;
      height: 20px;
    }
  }
}

.passIcon,
.passIconClosed {
  position: absolute;
  right: 20px;
  top: 18px;
  cursor: pointer;
}

.passIconClosed {
  top: 15px;
}

.formFooter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}

.forget {
  color: $blue_color;
  transition: all 0.5s ease;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-decoration: none;

  &:hover {
    color: $hover_blue_color;
    transition: all 0.5s ease;
  }
}

.nav {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 30px;
  // column-gap: 36px;

  .navLink {
    color: $secondary_color;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-decoration: none;

    &:hover {
      color: $primary_color;
    }

    &::after {
      content: "";
      display: inline-block;
      width: 1px;
      height: 14px;
      background: $secondary_color;
      margin-left: 16px;
      margin-right: 16px;
    }
    &:last-of-type {
      &::after {
        display: none;
      }
    }
  }
}

.err {
  color: $error_color;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  position: absolute;
}
