@import "styles/mixins.scss";

.root {
  height: 50px;
  width: 100%;
  box-sizing: border-box;
  background: #ffffff;
  border: 1px solid #efefef;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  padding: 0 13px;

  &.disabled {
    cursor: not-allowed;
  }
}

.leftBtn,
.rightBtn {
  width: 24px;
  height: 24px;
  @include invisible-button;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  cursor: pointer;

  &:hover {
    fill: $third_color;
  }

  .icon {
    width: 24px;
    height: 24px;
  }

  &.disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }
}
.placeholder {
  color: $secondary_color;
  transition: all 0.3s ease;

  &.valueSetted {
    position: absolute;
    top: -12px;
    background: #fff;
    left: 20px;
    font-size: 13px;
    padding: 0 5px;
  }
}
