@import "styles/mixins.scss";

.root {
  width: 100%;
  .header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .head {
      @include anyText(#000, 700, 24px, 54px);
    }

    .newBtn,
    .applyBtn {
      @include app_button;
      margin-left: auto;
    }

    .applyBtn {
      @include anyText(#fff, 500, 17px, 100%);
      background: $third_color;
      margin-left: 20px;
    }

    .exportAllBtn {
      @include app_button;
      margin-left: 20px;
    }

    .exportAllBtn,
    .newBtn {
      &:hover {
        background: $hover_blue_color;
        color: #fff;
        border-color: #fff;
      }
    }
  }
}

.content {
  margin-top: 15px;
}
