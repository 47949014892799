@import "styles/mixins.scss";

.root {
  width: 330px;
  height: 550px;
  padding: 30px 20px;
  box-sizing: border-box;
  @include box;
  position: relative;

  .loader {
    position: absolute;
    left: calc(50% - 40px);
    top: calc(50% - 40px);
  }

  .title {
    @include anyText(#000, 600, 20px, 30px);
    margin-bottom: 22px;
  }

  .indicatorItem {
    display: flex;
    align-items: center;
    margin-bottom: 24px;

    .name,
    .units {
      @include anyText($secondary_color, 400, 14px, 14px);
      margin-right: 3px;
      font-weight: 500;
    }

    .count {
      @include anyText($third_color, 700, 14px, 14px);
      margin-right: 3px;
    }
    .countBenchMark {
      @include anyText($secondary_color, 700, 14px, 14px);
      margin-right: 3px;
    }
  }
}
