.root {
  .head {
    margin-top: 45px;
    font-weight: 600;
    font-size: 36px;
    line-height: 54px;
    color: #000000;
    margin-bottom: 15px;
  }
}
