@import "styles/mixins.scss";

.root {
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  margin-top: 6px;

  .exportButtons_container {
    display: flex;
    justify-content: space-between;

    min-width: 398px;

    .exportBtn {
      @include app_button;

      &.exportDayBtn {
        margin-right: 16px;
      }
    }
  }
}
