@import "styles/mixins.scss";

.root {
  width: 100%;
  height: 550px;
  @include box;
  padding: 25px;
  box-sizing: border-box;
  position: relative;

  .btn {
    position: absolute !important;
    @include app_button;
    left: unset;
    right: 25px;
    z-index: 1;
  }

  .loaderContainer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
    @include box;
    box-sizing: border-box;
    z-index: 1;
  }
}
