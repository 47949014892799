.root {
  padding: 20px;
  box-sizing: border-box;
  position: relative;
  height: 550px;

  .loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
