@import "styles/mixins.scss";

.root {
  @include box;
  position: relative;

  .loaderContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .content {
    max-height: calc(100vh - 265px);
    overflow-y: auto;
    @include scrollbar;
  }
}
