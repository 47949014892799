@import "styles/index.scss";

.root {
  margin-top: 20px;
  text-align: center;
  width: 100%;
  color: $blue_color;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.02em;
  text-decoration: none;
}
