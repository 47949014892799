@import "styles/index.scss";

.sidebar-item-nav {
  display: flex;
  align-items: center;
  column-gap: 12px;
  text-decoration: none;
  padding: 15px 20px;
  border-radius: 16px;
  background: inherit;
  max-width: 200px;
}

@media screen and (max-width: 1280px) {
  .sidebar-item-nav {
    padding: 7.5px 10px;
    column-gap: 6px;
    border-radius: 12px;

    &.closed {
      width: 20px;
    }
  }
}
