@import "styles/index.scss";

.form {
  margin-top: auto;
  width: 100%;
  max-width: 410px;
}

.title {
  font-weight: 700;
  font-size: 32px;
  line-height: 56px;
  letter-spacing: -0.02em;
  color: $primary_color;
  margin-bottom: 15px;
}

.subtitle {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 100%;
  letter-spacing: -0.02em;
  color: $secondary_color;
  margin-bottom: 30px;
}

.footer {
  text-align: center;
  color: $secondary_color;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin-top: auto;
  margin-bottom: 20px;
}
