@import "styles/mixins.scss";

.root {
  position: relative;
  margin-top: 20px;
  border: 1px solid $table_border_color;
  border-radius: 15px;
  background: #ffffff;
  min-height: 100px;

  &.scenariosLoading {
    height: 200px;
  }

  .loaderContainer {
    position: absolute;
    top: calc(100% - 143px);
    left: calc(50% - 40px);
  }

  .tableHeader {
    display: grid;
    grid-template-columns: 80% 20%;
    font-size: 14px;
    color: $secondary_color;

    .tableHeaderColumn:first-of-type {
      height: 50px;
      box-sizing: border-box;
      padding: 15px 20px;
      border-right: 1px solid $table_border_color;
    }
  }

  .tableBody {
    .tableBodyRow {
      display: grid;
      grid-template-columns: 80% 20%;
      
      .tableBodyColumn {
        display: grid;
        align-items: center;
        height: 50px;
        box-sizing: border-box;
        border-right: 1px solid $table_border_color;
        border-top: 1px solid $table_border_color;

        &:first-of-type {
          padding: 15px 20px;
          font-size: 12px;
          font-weight: 500;
        }

        &:last-of-type {
          padding: 5px 15px;
        }
      }
    }
    button {
      @include app_button;
    }
  }
}

@media screen and (max-width: 1210px) {
  .root {
    .tableBody {
      button {
        font-size: 16px;
      }
    }
  }
}

@media screen and (max-width: 1210px) {
  .root {
    .tableBody {
      button {
        font-size: 14px;
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .root {
    .tableBody {
      button {
        font-size: 12px;
      }
    }
  }
}