@import "styles/mixins.scss";

.root {
  width: 100%;
  display: flex;
  align-items: center;
  column-gap: 30px;
  display: grid;
  grid-template-columns: 1fr 330px;

  &.fullWidth {
    grid-template-columns: 1fr;
  }
}

.chart {
  position: relative;
  height: 550px;
  @include box;
}
