@import "styles/mixins.scss";

.root {
  width: calc(100% - 342px);
  height: 323px;
  @include box;
  box-sizing: border-box;
  overflow: hidden;

  .wrapper {
    width: 100%;
    position: relative;
  }
}

.filename {
  position: absolute;
  top: 51px;
  background: #fff;
  left: 50px;
  z-index: 10;
  max-width: calc(100% - 397px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
}

.downloadBtn,
.refreshBtn,
.questionBtn,
.zoomInBtn,
.zoomInBtn,
.zoomOutBtn {
  position: absolute;
  right: 8px;
  top: 55px;
  @include invisible-button;
  cursor: pointer;
  z-index: 1;

  .icon {
    fill: $secondary_color;
  }

  &:hover {
    .icon {
      fill: $third_color;
    }
  }
}

.zoomInBtn {
  top: 70px;
}

.zoomOutBtn {
  top: 90px;
}

.zoomInBtn,
.zoomOutBtn {
  border: 2px solid $secondary_color;
  border-radius: 50%;
  box-sizing: border-box;
  color: $secondary_color;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 600;
  padding: 0 !important;
  width: 20px;
  height: 20px;
  flex-shrink: 0;

  &:hover {
    border: 2px solid $third_color;
    color: $third_color;
  }
}

.refreshBtn {
  top: 81px;
}

.questionBtn {
  top: 105px;
  right: 9px;
  display: flex;
  align-items: center;
  justify-content: center;

  .icon {
    width: 17px;
    height: 17px;
  }
}

.name {
  position: absolute;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;

  color: #000000;
  top: 30px;
  left: 20px;
  z-index: 1;
}

.subtitle {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 1;
  right: 40px;
  top: 38px;

  font-weight: 400;
  font-size: 14px;
  line-height: 100%;
  /* identical to box height, or 14px */

  text-align: right;

  color: #6e7991;

  .data {
    color: $third_color;
    font-weight: 500;
    margin-left: 4px;
    display: block;
  }
}
.yAxisName {
  position: absolute;
  top: 62px;
  left: 22px;

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  z-index: 1;
  color: $secondary_color;
}

@media screen and (max-width: 1280px) {
  .root {
    width: calc(100% - 218px);
  }
}

.loader {
  position: absolute;
  top: calc(50% - 20px);
  left: calc(50% - 20px);
  z-index: 1;
}
