@import "styles/mixins.scss";

.root {
  display: grid;
  grid-template-columns: 31.42% 31.42% 31.42%;
  grid-gap: 30px 2.86%;

  height: 430px;
  margin-bottom: 30px;

  .loaderContainer {
    display: flex;
    justify-content: center;
    align-items: center;

    height: 200px;
    border-radius: 15px;

    background-color: white;
  }
}
