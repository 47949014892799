@import "styles/index.scss";

.root {
  color: $secondary_color;
  font-weight: 400;
  font-size: 14.3px;
  line-height: 20px;
  padding: 2px 0 0 0;
  cursor: pointer;

  &:hover {
    color: $hover_secondary_color;
  }

  &:after {
    content: "";
    display: block;
    width: 100%;
    height: 1px;
    background: $hover_secondary_color;
    margin-top: 2px;
  }

  &:last-of-type {
    &::after {
      display: none;
    }
  }
}
