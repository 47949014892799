@import "styles/mixins.scss";

.root {
  position: relative;
}
.header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .head {
    @include anyText(#000, 600, 20px, 30px);
  }

  .btn {
    @include app_button;
    width: 120px;
  }
}

.body {
  width: 100%;
  margin-top: 15px;

  &.loading {
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    background: #fff;
    border-radius: 15px;

    .loaderContainer {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
