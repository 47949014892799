.root {
  display: flex;
  align-items: center;
  column-gap: 30px;
  row-gap: 24px;
}

.chartContainer {
  width: 690px;
  height: 323px;
  background: #fff;
  border-radius: 16px;
}
