.root {
  width: 100%;
  height: 102px;
  padding-top: 62px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .rightSide {
    display: flex;
    align-items: center;
    column-gap: 15px;
  }

  .line {
    height: 40px;
    width: 1px;
    background: rgba(110, 121, 145, 0.5);
  }
}

@media screen and (max-height: 800px) {
  .root {
    padding-top: 20px;
    height: 70px;
  }
}
