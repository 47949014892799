@import "styles/mixins.scss";

.container {
  min-width: 154px;
  height: 98px;
  padding-top: 12px;
  margin-right: 9px;

  .wrapper {
    position: relative;
    width: 100%;
    height: 86px;
    @include box;
    padding: 6px 10px;
    box-sizing: border-box;

    .angle {
      position: absolute;
      top: -7px;
      right: 10px;
      width: 13px;
      height: 6px;
      background: #ffffff;
      border: 1px solid #efefef;
      border-bottom: none;
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
    }
  }
}
.btn {
  @include invisible-button;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}
