@import "styles/mixins.scss";

.root {
  width: 330px;
  height: 323px;
  padding: 20px;
  box-sizing: border-box;
  @include box;
  row-gap: 24px;
  display: flex;
  flex-direction: column;
  margin-left: auto;
  flex-shrink: 0;

  .title {
    @include anyText(#000, 600, 20px, 30px);
  }

  .items {
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    overflow: auto;
    height: 100%;

    @include scrollbar;
  }
}
.item {
  display: flex;
  align-items: center;

  .label {
    @include anyText($secondary_color, 400, 14px, 16px);
  }

  .value {
    @include anyText($third_color, 600, 14px, 16px);
    margin-left: 3px;
  }
}
