@import "styles/index.scss";

.root {
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;

  &.disabled {
    cursor: not-allowed;
  }

  .label {
    margin-left: 8px;
    font-weight: 400;
    font-size: 14px;
    line-height: 100%;
    color: $secondary_color;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;

    &.error {
      color: $error_color;
    }
  }

  .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    box-sizing: border-box;
    border: 1px solid #efefef;
    flex-shrink: 0;

    &.error {
      border-color: $error_color;
    }

    .circle {
      width: 12px;
      height: 12px;
      box-sizing: border-box;
      border-radius: 50%;
      background: inherit;

      &.selected {
        background: $third_color;

        &.error {
          background: $error_color;
        }
      }
    }
  }
}
