@import "styles/mixins.scss";

.root {
  width: 100px;
  height: 100px;
  background: #efefef;
  border-radius: 15px;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 24px 18px;
  box-sizing: border-box;
  @include text-overflow;

  .size,
  .name {
    font-weight: 500;
    font-size: 18px;
    line-height: 100%;
    text-align: center;
    color: #000000;
    margin-bottom: 18px;
    @include text-overflow;
    width: 100%;
  }

  .name {
    font-size: 13px;
    font-weight: 400;
    margin-bottom: 0;
  }
}
