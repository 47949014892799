.root {
  padding: 60px 20px 10px 10px;
  width: calc(100% - 30px);

  .header {
    margin-bottom: 30px;

    &__title {
      padding-left: 55px;
      font-size: 13px;
    }
  }
}