.header {
  display: flex;
  align-items: center;
  column-gap: 30px;
  position: absolute;
  top: -2px;
  right: 145px;
}

.dropdown {
  width: 150px;
  height: 39px;
}
