@import "styles/mixins.scss";

.root {
  @include box;
  box-sizing: border-box;

  &.loading {
    border: none;
    opacity: 0.5;
  }
  .tableHead,
  .tableBody {
    display: grid;
    width: 100%;
    min-width: 100%;
    div {
      height: 50px;
      border-right: 1px solid $table_border_color;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $secondary_color;
      @include anyText($secondary_color, 400, 14px, 21px);

      &:first-of-type {
        width: 50px;
      }

      &:not(:first-of-type) {
        min-width: 70px;
      }

      &:last-of-type {
        border-right: none;
      }
    }
  }

  .tableHead {
    div {
      border-bottom: 1px solid $table_border_color;
    }
  }

  .tableBody {
    div {
      @include anyText(#000, 500, 14px, 21px);
    }
  }
}

@media screen and (max-width: 1280px) {
  .root {
    .tableHead,
    .tableBody {
      div {
        font-size: 12px;
      }
    }
  }
}
