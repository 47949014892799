@import "styles/index.scss";

.root {
  display: flex;
  height: 100%;
  overflow: hidden;
  background-color: #fff;
}

.leftPart {
  position: relative;
  min-width: 410px;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;
  width: 50%;
}

.rightPart {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  background-repeat: no-repeat;
  background-position: center right;
  background-image: url(/assets/webp/sign_in_bg.webp);
  background-size: contain;
  border-bottom-left-radius: 96px;
  position: relative;
  width: 50%;
}

.translation {
  position: absolute;
  top: 60px;
  right: 60px;
}

.nav {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  // column-gap: 36px;
  position: absolute;
  bottom: 18px;
  right: calc(50% - 100px);

  .navLink {
    color: $secondary_color;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-decoration: none;

    &:hover {
      color: $secondary_color;
      text-shadow: 2px 2px 2px #fff, 0 2px 2px #fff, -2px 0 2px #fff,
        0 -2px 2px #fff;
    }

    &::after {
      content: "";
      display: inline-block;
      width: 1px;
      height: 14px;
      background: $secondary_color;
      margin-left: 16px;
      margin-right: 16px;
    }

    &:last-of-type {
      &::after {
        display: none;
      }
    }
  }
}

.logo {
  width: 310px;
  height: 142px;
  position: absolute;
  right: calc(50% - 194px);
  top: calc(50% - 270px);
}

@media screen and (max-width: 1000px) {
  .nav {
    bottom: 50px;
    left: 100px;
  }
}
