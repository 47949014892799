@import "styles/mixins.scss";

.root {
  @include box;
  padding: 30px 20px 20px 20px;
  box-sizing: border-box;
  max-width: 330px;
  width: 100%;
  height: 323px;
  display: flex;
  flex-direction: column;
  row-gap: 20px;

  .title {
    @include title;
  }

  .list {
    width: 100%;
    height: 180px;
    overflow-y: auto;
    @include scrollbar;
    row-gap: 24px;
    display: flex;
    flex-direction: column;
  }

  .btn {
    @include button;
    width: 100%;
    height: 50px;
    background: $third_color;
  }
}

@media screen and (max-height: 800px) {
  .root {
    max-width: 312px;
    padding: 10px;
  }
}

@media screen and (max-width: 1280px) {
  .root {
    max-width: 200px;
    width: 100%;
  }
}
