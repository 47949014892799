@import "styles/index.scss";

.container {
  display: flex;
  flex-wrap: wrap;
  gap: 8px 24px;
  width: 330px;
  min-height: 328px;
  padding: 16px 32px;
  background-color: #ffffff;
  border-radius: 15px;
  border: 1px solid rgb(239, 239, 239);
  box-sizing: border-box;

  .yearItem {
    width: 72px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: 1px solid #ffffff;
    box-sizing: border-box;

    &:hover {
      background-color: #F5F5F5;
      border: 1px solid #6E7991;
      border-radius: 100px;
    }

    &__selected {
      background-color: #F5F5F5;
      border: 1px solid #6E7991;
      border-radius: 100px;
    }
  }
}

.btn {
  position: relative;
  width: 227px;
  height: 44px;
  padding: 0 13px;
  box-sizing: border-box;
  border-radius: 16px;
  border: 1px solid rgb(239, 239, 239);
  text-align: center;
  font-size: 17px;
  font-weight: 400;
  background-color: #ffffff;

  &:focus {
    outline: none;
  }
}

.arrow {
  position: absolute;
  right: 15px;
  bottom: 15px;
  color: $secondary_color;  
  transition: all 0.3s linear;

  &.open {
    transform: rotate(180deg);
    transition: all 0.3s linear;
  }
}