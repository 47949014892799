@import "styles/mixins.scss";
@import "styles/index.scss";

.root {
  display: flex;
  flex-direction: column;
  max-width: 410px;
  width: 100%;
  color: #4d4d4d;

  &.error {
    .input {
      border-color: $error_color;
      color: $error_color;

      &::placeholder {
        color: $error_color;
      }
    }
  }

  .submitError {
    color: $error_color;
    position: absolute;
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
  }

  .input {
    @include input;
    width: 100%;
  }

  .alert {
    color: red;
  }

  .inputContainer {
    position: relative;
    margin-bottom: 30px;

    .icon {
      position: absolute;
      top: 16px;
      right: 20px;
      fill: #df2d2d;
      width: 20px;
      height: 20px;
    }

    &.inputContainer2 {
      margin-bottom: 45px;
    }
  }
}

.err {
  color: $error_color;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  position: absolute;
}

.btn {
  cursor: pointer;
  @include button;
  font-weight: 600;
  font-size: 20px;
  height: 50px;
}

.passIcon,
.passIconClosed {
  position: absolute;
  right: 20px;
  top: 18px;
  cursor: pointer;
}

.passIconClosed {
  top: 15.5px;
}

.formFooter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}

.forget {
  color: $blue_color;
  transition: all 0.5s ease;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-decoration: none;

  &:hover {
    color: $hover_blue_color;
    transition: all 0.5s ease;
  }
}

.label {
  font-weight: 500;
  font-size: 14px;
  color: #000;
  margin-bottom: 10px;
  display: block;
}

.title {
  font-weight: 700;
  font-size: 32px;
  line-height: 56px;
  color: #000000;
  margin-bottom: 40px;
}

.subtitle {
  font-weight: 400;
  font-size: 14px;
  line-height: 100%;
  letter-spacing: -0.02em;
  color: #000000;
  margin-bottom: 20px;
}

.footer {
  @include formFooter;
  position: absolute;
  bottom: 20px;
}

.link {
  margin-top: 20px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: -0.02em;
  color: $blue_color;
  cursor: pointer;
}
