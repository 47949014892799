@import "styles/mixins.scss";

.root {
  position: relative;
  padding: 15px 0 0 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  padding-bottom: 20px;

  .topLine,
  .bottomLine {
    display: flex;
    width: 100%;
    column-gap: 30px;
    justify-content: space-between;
    align-items: stretch;
  }

  .topLine {
    padding-top: 10px;
    height: 434px;
  }
}

.btn {
  @include button;
  background: $third_color;
  width: 113px;
  height: 34px;
  position: absolute;
  top: -23px;
  right: 0;
  font-size: 17px;
}

.emptyPlaceholder {
  @include box;
  display: flex;
  width: calc(100% - 252px);
  flex-direction: column;
  position: relative;
  padding: 20px;
  box-sizing: border-box;

  .emptyTitle {
    @include anyText(#000, 600, 20, 30px);
  }

  .emptyText {
    @include anyText($secondary_color, 400, 16px, 20px);
    align-self: center;
    margin: auto;
  }
}

@media screen and (max-width: 1200px) {
  .root {
    row-gap: 15px;
  }

  .topLine,
  .bottomLine {
    column-gap: 10px;
  }
}

.dropArea {
  min-height: auto !important;
}

@media screen and (max-width: 1250px) {
  .dropArea {
    width: 100%;
    min-height: auto !important;
    // min-height: 394px;
  }
  .placeholder {
    flex-shrink: 1 !important;
    // width: 100%;
  }
}

@media screen and (max-height: 800px) {
  .root {
    row-gap: 15px;

    .topLine,
    .bottomLine {
      column-gap: 15px;
    }
  }
}

@media screen and (max-height: 800px) {
  .root {
    .dropzone {
      height: 100%;
      min-height: auto;
    }
  }
}
