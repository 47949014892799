@import "styles/index.scss";

.btn {
  background: #ffffff;
  border: 1px solid #efefef;
  border-radius: 38px;
  padding: 12px 13px 11px 12px;
  color: $secondary_color;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-left: auto;
  text-transform: uppercase;
  font-weight: bold;
}

.arrow {
  margin-left: 6px;
  margin-bottom: 3px;
  color: $secondary_color;
  transition: all 0.3s linear;

  &.open {
    transform: rotate(180deg);
    transition: all 0.3s linear;
  }
}
.container {
  background: white;
  /* top: 14px; */
  padding: 8px 15px 8px 12px;
  box-sizing: border-box;
  border-radius: 19px;
  border: 1px solid #efefef;

  .langItem {
    color: $secondary_color;
    cursor: pointer;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-transform: uppercase;
    margin-bottom: 5px;

    &:hover {
      color: $primary_color;
    }
  }
}
