@import "styles/index.scss";

.label {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: $secondary_color;

  .hidden {
    display: none;
  }
}
