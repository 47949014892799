@import "styles/index.scss";
@import "styles/mixins.scss";

.root {
  @include box;
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(100% - 342px);
  flex-direction: column;
  position: relative;

  &.error {
    height: 323px;
    display: flex;
    align-items: center;
    justify-content: center;
    .text {
      position: static;
      margin: 10px;
    }
  }

  .text {
    position: absolute;
    top: 25px;
    left: 55px;
    color: $secondary_color;
    text-align: center;
  }
}

@media screen and (max-width: 1280px) {
  .root {
    width: calc(100% - 218px);
  }
}
