@import "styles/index.scss";
.root {
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;

  &.disabled {
    cursor: not-allowed;
  }
}

.wrapper {
  box-sizing: border-box;
  padding: 9px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.5s ease-in;
  background-color: transparent;

  &:hover {
    background-color: rgba(25, 118, 210, 0.04);
    transition: all 0.5s ease-in;
  }
}

.checkbox {
  width: 18px;
  height: 18px;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.5s ease;
  border: 2px solid $blue_color;
  box-sizing: border-box;

  &.secondary {
    border: 2px solid #6e7991;
  }

  &:hover {
    background: $hover_blue_color;
    transition: all 0.5s ease;
    border-color: $hover_blue_color;
  }

  .icon {
    width: 11.3px;
    height: 8px;
  }

  &.checked {
    border: 2px solid $blue_color;
    background: $blue_color;
  }

  &.notChecked {
    background: transparent;
  }
}

.label {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
  cursor: pointer;
}
