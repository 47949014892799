@import "styles/index.scss";

.rion-alert {
  .swal2-actions {
    .swal2-confirm,
    .swal2-cancel {
      min-width: 195px;
      display: flex;
      align-items: center;
      border-radius: 16px;
      border: 2px solid $third_color;
      background: white !important;
      color: $third_color;
    }

    .swal2-cancel {
      background: $third_color !important;
      color: white;
    }
  }
}

.reset-zoom-chart-btn {
  border: none;
  width: 20px;
  height: 20px;
  background: url(./assets/svg/ic_round-refresh.svg);
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  right: -8px;
  top: 77px;
}

// #googlechart-control-0-1 {
//   visibility: hidden;
// }

// .d-block {
//   visibility: visible !important;
// }

// .d-none {
//   display: none !important;
// }
