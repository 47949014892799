@import "styles/index.scss";
@import "styles/mixins.scss";

.root {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 110px;
  border-top: 1px solid $table_border_color;
  text-align: center;

  &.hasScroll {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 103px;
  }

  &.isHideActions {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;

    &.hideSomeColumns {
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    }
  }

  &.hideSomeColumns {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 110px;

    &.hasScroll {
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 103px;
    }
  }

  div {
    @include anyText(#000, 500, 12px, 18px);
    padding: 15px 5px;
    border-right: 1px solid $table_border_color;
    word-break: break-all;
    display: flex;
    align-items: center;
    justify-content: center;
    // min-width: 62px;

    &:last-of-type {
      border-right: none;
    }
  }
}

@media screen and (max-width: 1500px) {
  .root {
    div {
      @include anyText(#000, 500, 11px, 15px);
    }
  }
}

@media screen and (max-width: 1415px) {
  .root {
    div {
      @include anyText(#000, 500, 10px, 15px);
    }
  }
}

@media screen and (max-width: 1320px) {
  .root {
    div {
      @include anyText(#000, 500, 9px, 15px);
    }
  }
}

.actions {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 14px;
}
.icon {
  width: 20px;
  height: 20px;
  fill: $secondary_color;
  cursor: pointer;

  &.disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }

  &:hover {
    opacity: 0.9;
  }
}
