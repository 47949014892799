@import "styles/mixins.scss";

.root {
  padding: 50px 90px;
  box-sizing: border-box;
  @include box;
  max-width: 600px;
  margin-top: 15px;
  position: relative;
  margin-bottom: $site_margin_bottom;

  &.loading {
    opacity: 0.7;
  }

  .loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .title {
    @include anyText(#000, 500, 16px, 24px);
    margin-bottom: 15px;
  }
  .year {
    width: 100%;
    margin-bottom: 30px;
  }
  .role,
  .hedging {
    margin-bottom: 30px;
  }

  .btn {
    @include button;
    background-color: $third_color;
    max-width: 420px;
    width: 100%;
    height: 50px;
    margin-top: 40px;
  }
}
