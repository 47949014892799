.analyze-chart-rion {
  margin-bottom: 20px;

  .apexcharts-canvas {
    z-index: 0;
    .apexcharts-toolbar {
      top: 50px !important;
    }
  }
}
