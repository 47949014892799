@import "styles/index.scss";

.root {
  display: flex;

  .wrapper {
    width: 100%;
    background: $site_color;
    padding: 0 45px 0 48px;
    box-sizing: border-box;

    &.fullWidth {
      width: 100%;
      padding: 0;
    }
  }
}
