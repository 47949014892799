@import "styles/mixins.scss";

.root {
  position: relative;
  height: 200px;

  .pricePerMWhContainer {
    position: absolute;
    top: 20px;
    right: 10px;

    line-height: 16.7px;
    z-index: 1;

    .price {
      &.upTrend {
        @include anyText($third_color, 500, 20px);
      }
      &.downTrend {
        @include anyText($error_color, 500, 20px);
      }
      &.directTrend {
        @include anyText($secondary_color, 500, 20px);
      }
    }

    label {
      @include anyText($secondary_color, 500, 13px, 16px);
    }

    .price,
    label {
      font-family: "Poppins";
    }
  }

  div:nth-child(2) > div {
    padding-top: 0;
    svg {
      border-radius: 15px;
    }
  }
}

@media screen and (max-width: 1280px) {
  .root {
    .pricePerMWhContainer {
      top: 23px;
      .price.upTrend,
      .price.downTrend,
      .price.directTrend {
        font-size: 17px;
      }
    }
  }
}

@media screen and (max-width: 1220px) {
  .root {
    .pricePerMWhContainer {
      top: 24px;
      .price.upTrend,
      .price.downTrend,
      .price.directTrend {
        font-size: 15px;
      }
    }

    & div:nth-child(2) div svg > text:first-of-type {
      font-size: 18px;
    }
  }
}

@media screen and (max-width: 1150px) {
  .root {
    .pricePerMWhContainer {
      .price.upTrend,
      .price.downTrend,
      .price.directTrend {
        font-size: 13px;
      }
      label {
        font-size: 12px;
      }
    }
  }
}

@media screen and (max-width: 1050px) {
  .root {
    .pricePerMWhContainer {
      .price.upTrend,
      .price.downTrend,
      .price.directTrend {
        font-size: 12px;
      }
      label {
        font-size: 10px;
      }
    }

    & div:nth-child(2) div svg > text:first-of-type {
      font-size: 17px;
    }
  }
}

@media screen and (max-width: 1000px) {
  .root {
    & div:nth-child(2) div svg > text:first-of-type {
      font-size: 15px;
    }
  }
}
