@import "styles/index.scss";

.root {
  width: 300px;
  height: 100vh;
  background: #fff;
  padding: 35px 20px;
  box-sizing: border-box;
  position: relative;

  .logo {
    width: 192px;
    height: 88px;
    cursor: pointer;

    &.closed {
      width: 58px;
      height: 88px;
    }
  }

  .angleContainer {
    position: absolute;
    top: 67px;
    right: -12px;
    cursor: pointer;
    width: 24px;
    height: 24px;
    box-sizing: border-box;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    border: 1px solid #efefef;
    transition: all 0.3s ease;

    &:hover {
      background: $secondary_color;
      transition: all 0.3s ease;

      .icon {
        fill: #fff;
      }
    }
    .icon {
      fill: $secondary_color;
      transform: rotate(180deg);

      &.closed {
        transform: rotate(0deg);
      }
    }
  }

  &.closed {
    width: 100px;
  }
}
