@import "styles/mixins.scss";

.root {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 110px;
  @include anyText($secondary_color, 400, 14px, 21px);
  text-align: center;
  min-height: 70px;

  &.hideSomeColumns {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 110px;
  }

  &.isHideActions {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;

    &.hideSomeColumns {
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    }
  }

  &.hasScroll {
    border-bottom: 1px solid $table_border_color;
  }

  .item {
    padding: 11px 0 17px 0;
    border-right: 1px solid $table_border_color;
    display: flex;
    align-items: center;
    justify-content: center;
    word-break: break-word;
    &.last {
      border-right: none;
    }

    &:last-of-type {
      border-right: none;
    }
  }
}

@media screen and (max-width: 1200px) {
  .root {
    @include anyText($secondary_color, 400, 12px, 18px);
  }
}

@media screen and (max-width: 1000px) {
  .root {
    @include anyText($secondary_color, 400, 11px, 18px);
  }
}
