@import "styles/index.scss";

.root {
  position: relative;
  min-height: 323px;
  margin-top: 15px;
  padding: 20px 40px 17px 20px;
  box-sizing: border-box;
  border-radius: 15px;
  background-color: #fff;

  .loaderContainer {
    height: 323px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;

    &__title {
      font-size: 20px;
    }

    &__legend {
      display: flex;
      gap: 20px;
      
      &_item {
        display: flex;
        align-items: center;
        gap: 8px;

        &_notActive {
          div {
            background-color: $secondary_color;
          }
        }

        span {
          color: #6E7991
        }
      }

      &_square {
        width: 16px;
        height: 16px;
        border-radius: 2px;

        cursor: pointer;
      }
    }
  }

  .graphContainer {
    min-width: 100%;
  }

  .errorContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 286px;
    font-weight: 500;
  }

  .refreshIcon,
  .saveIcon {
    position: absolute;
    right: 12px;
    cursor: pointer;
    fill: $secondary_color;
  }

  .refreshIcon {
    top: 186px;
  }

  .saveIcon {
    top: 217px;
  }
}