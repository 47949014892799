@import "styles/index.scss";

.avatar,
.default {
  width: 40px;
  height: 40px;
  cursor: pointer;
  border-radius: 50%;
  text-transform: uppercase;
}

.default {
  background: #6e799177;
  color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
