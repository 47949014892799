@import "styles/index.scss";
@import "styles/mixins.scss";

.root {
  .header {
    display: flex;
    align-items: center;

    .datePicker {
      display: flex;
      align-items: center;
      gap: 8px;
      margin-left: 56px;

      &__title {
        font-size: 20px;
        font-weight: 500;
        color: $secondary_color;
      }

      &__toggler {
        width: 230px;
        height: 44px;
      }
    }
  }

  .yearPickerContainer {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: 45px;

    .yearPicker__title {
      font-size: 20px;
      font-weight: 500;
      color: $secondary_color;
    }

    .yearPicker__toggler {
      width: 227px;
      height: 44px;
    }
  }
}