@import "styles/index.scss";

@mixin input {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 100%;
  box-sizing: border-box;
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  min-width: 0px;
  width: 100%;
  animation-name: mui-auto-fill-cancel;
  animation-duration: 10ms;
  padding: 13.5px 24px;
  box-sizing: border-box;
  background: #ffffff;
  border: 1px solid #efefef;
  border-radius: 16px;
  color: #000;
  outline: none;
  letter-spacing: -0.02em;

  &::placeholder {
    color: $secondary_color;
  }
}

@mixin button {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  outline: 0px;
  border: 0px;
  margin: 0px;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  text-decoration: none;
  font-weight: 600;
  font-size: 20px;
  line-height: 1.75;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  min-width: 64px;
  padding: 15px 16px;
  border-radius: 16px;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  color: rgb(255, 255, 255);
  background-color: #3f1fed;
  box-shadow: rgb(0 0 0 / 20%) 0px 3px 1px -2px,
    rgb(0 0 0 / 14%) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 1px 5px 0px;
  text-transform: none;

  &:hover {
    text-decoration: none;
    background-color: #534e70;
    box-shadow: rgb(0 0 0 / 20%) 0px 2px 4px -1px,
      rgb(0 0 0 / 14%) 0px 4px 5px 0px, rgb(0 0 0 / 12%) 0px 1px 10px 0px;
  }

  &:disabled {
    cursor: not-allowed;
    background: $secondary_color;
  }
}

@mixin app_button {
  @include button;
  @include anyText($blue_color, 500, 17px 17px);
  height: 34px;
  border: 2px solid $blue_color;
  border-radius: 16px;
  background-color: transparent;
  box-shadow: none;

  &:hover {
    color: #fff;
    border-color: #fff;
    background: $hover_blue_color;
  }
}

@mixin paper {
  background-color: rgb(255, 255, 255);
  color: rgba(0, 0, 0, 0.87);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 4px;
  box-shadow: rgb(0 0 0 / 20%) 0px 2px 1px -1px,
    rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px;
  overflow: hidden;
}

@mixin formFooter {
  color: $secondary_color;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
}

@mixin invisible-button {
  background: inherit;
  outline: none;
  border: none;
}

@mixin scrollbar($height: auto) {
  &::-webkit-scrollbar-thumb {
    background-color: #cecece;
    border-radius: 10px;
    position: absolute;
  }

  &::-webkit-scrollbar-track {
    border-top-right-radius: 22px;
    border-bottom-right-radius: 6px;
    overflow: hidden;
    margin-top: 0;
    position: absolute;
  }

  &::-webkit-scrollbar {
    background: #f1f1f1;
    width: 7px;
    height: $height;
    border-bottom-right-radius: 6px;
    position: absolute;
  }
}

@mixin material-input {
  height: 50px;
  background: #ffffff;
  border: 1px solid #efefef;
  border-radius: 16px;
  padding-left: 24px;
  box-sizing: border-box;

  &:disabled {
    color: $hover_secondary_color;
  }
}

@mixin text-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  white-space: nowrap;
}

@mixin title {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #000000;
}

@mixin anyText($color, $weight, $size, $lheight: 100%) {
  font-weight: $weight;
  color: $color;
  font-size: $size;
  line-height: $lheight;
}

@mixin box {
  background: #fff;
  border: 1px solid #efefef;
  border-radius: 15px;
}
