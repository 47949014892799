@import "styles/mixins.scss";

.root {
  .pricesOfDateContainer {
    display: grid;
    grid-template-columns: 31.42% 1fr;
    grid-gap: 2.86%;
    align-items: center;

    margin-top: 15px;
    margin-bottom: 15px;

    .datePickerContainer {
      display: grid;
      grid-template-columns: 97px 1fr;
      grid-gap: 2.42%;
      align-items: center;

      & > div:first-of-type div input {
        height: 29px !important;
      }

      & > div:nth-of-type(2) > div:nth-child(2) {
        z-index: 2;
      }
    }

    label {
      @include anyText($secondary_color, 500, 18px, 27px);
    }
  }

  .emptyStateContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 704px;

    span {
      @include anyText($secondary_color, 500, 18px, 27px);
    }
  }
}
