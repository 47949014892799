@import "styles/mixins.scss";

.content {
  height: calc(100vh - 150px);
  overflow: auto;
  @include scrollbar;
}
.head {
  margin-top: 6px;
}
