@import "styles/index.scss";
@import "styles/mixins.scss";

.root {
  @include box;
  transition: all 0.3s ease;
  max-width: 235px;
  width: 100%;
  height: 182px;
  padding: 35px 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &.disable {
    background: #e7e7e741;
    opacity: 0.8;
    cursor: not-allowed;
  }

  &:hover {
    border-color: $secondary_color;
    transition: all 0.3s ease;
  }

  &.selected {
    border-color: $third_color;
    transition: all 0.3s ease;
  }

  .year {
    @include anyText($secondary_color, 500, 20px, 24px);
    margin-bottom: 15px;
  }

  .percentage {
    @include anyText(#000, 600, 32px, 38px);
    margin-bottom: 15px;
  }

  .calculation {
    @include anyText($secondary_color, 500, 16px, 19px);
    text-align: center;
  }
}
