@import "styles/mixins.scss";

.root {
  min-height: 535px;
  @include box;
  padding: 20px;
  position: relative;
  max-width: calc(100vw - 343px);
  width: 100%;
  box-sizing: border-box;

  &.closedNavigation {
    max-width: calc(100vw - 210px);
  }

  .content {
    width: 100%;

    &.error {
      position: relative;
      height: 462px;

      .errorText {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 10;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 102px;
        box-sizing: border-box;
        text-align: center;
        background: white;
        @include anyText($secondary_color, 400, 16px, 22px);
      }

      .wrapper {
        opacity: 0.4;
      }
    }
  }

  &.loading {
    opacity: 0.7;
  }

  .loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
  }

  .title {
    @include anyText(#000, 600, 20px, 30px);
  }

  .wrapper {
    padding-top: 16.7px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    width: 100%;
  }

  .dropdown {
    max-width: 100px;
    width: 100%;
    position: absolute;
    top: 22px;
    right: 20px;
  }

  .dropdown,
  .table {
    &.hidden {
      display: none;
    }
  }

  .table {
    width: 100%;
    margin-top: 16px;
  }
}
