@import "styles/mixins.scss";

.root {
  margin-bottom: $site_margin_bottom;

  .heading {
    margin-bottom: 15px;
  }

  .profileRollOutCard {
    display: grid;
    grid-template-columns: 105px 270px;
    grid-gap: 15px;
    align-items: center;

    width: 405px;
    height: 130px;
    box-sizing: border-box;
    border-radius: 15px;
    border: 1px solid white;
    background-color: white;
    cursor: pointer;

    .avatarContainer {
      display: flex;
      justify-content: flex-end;
    }
    .description {
      h4 {
        @include anyText(#000, 600, 20px, 24px);
        margin-bottom: 6px;
      }
      span {
        @include anyText($secondary_color, 500, 16px, 19px);
      }
    }
    &:hover {
      border-color: #8bba05;
    }
  }
}
