@import "styles/mixins.scss";

.root {
  width: calc(100% - 5px);
  position: relative;
  padding-bottom: 24px;
  box-sizing: border-box;
  margin-left: 5px;
  margin-top: 15px;

  .title {
    margin-bottom: 14px;
    margin-left: 24px;
    @include anyText($secondary_color, 400, 14px, 100%);
  }

  .min,
  .max {
    position: absolute;
    @include anyText($secondary_color, 400, 14px, 100%);
    top: 58px;
  }

  .min {
    left: 0;
  }

  .max {
    right: 0;
  }
}
