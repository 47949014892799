@import "styles/mixins.scss";

.root {
  width: 100%;
  .title {
    @include anyText(#000, 600, 20px, 30px);
    margin-bottom: 15px;
  }

  .tables {
    width: 100%;
    display: flex;
    align-items: center;
    column-gap: 30px;
    row-gap: 30px;
    overflow: hidden;
    box-sizing: border-box;

    &.isRow {
      flex-direction: column;
      align-items: flex-start;
    }

    &.isRow {
      flex-direction: column;
      align-items: flex-start;
    }
  }
  .table {
    margin: 0 auto;
  }

  .content {
    width: 50%;
    max-width: calc(50% - 15px);
    box-sizing: border-box;
    overflow: auto;
    @include scrollbar(4px);
    display: flex;

    &.isRow {
      width: 100%;
      max-width: 100%;
      padding-bottom: 5px;
    }
  }
}
