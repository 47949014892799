@import "styles/mixins.scss";

.root {
  margin-bottom: 45px;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;

    .btn {
      @include app_button;
    }
  }

  .profileWrapper {
    margin-bottom: 30px;
  }

  &.loading {
    & > div:nth-child(3) {
      & > div:nth-of-type(3) {
        height: 382px;
      }
    }
  }

  & > div:nth-child(3) {
    min-height: 454px;
    & > div:nth-of-type(2) {
      height: 382px;
    }
  }
}
