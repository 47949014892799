@import "styles/mixins.scss";

.emptyPlaceholder {
  @include box;
  display: flex;
  width: calc(100% - 252px);
  flex-direction: column;
  position: relative;
  padding: 20px;
  box-sizing: border-box;

  .emptyText {
    @include anyText($secondary_color, 400, 16px, 20px);
    align-self: center;
    margin: auto;
  }
}
