@import "styles/index.scss";
@import "styles/mixins.scss";

.react-datepicker__input-container {
  width: calc(100% - 10px) !important;
  input {
    width: 100% !important;
    border: 1px solid #efefef !important;
    border-radius: 8px !important;
    padding: 7px 0px 6px 10px !important;
    font-family: "Poppins" !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 21px !important;
    outline: none !important;
    height: 19px !important;
  }
}

.react-datepicker__header {
  border: none;
  background: #fff;
}

.react-datepicker {
  @include box;
}

.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected {
  border-radius: 50%;
  border: 1px solid $secondary_color;
  color: #000;
  background: rgba(0, 0, 0, 0.1);

  &:hover {
    border-radius: 50%;
    border: 1px solid $secondary_color;
    color: #000;
    background: rgba(0, 0, 0, 0.2);
  }
}

.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
  border-radius: 50%;
}
