@import "styles/index.scss";
@import "styles/mixins.scss";

.root {
  height: 50px;
  width: 100%;
  box-sizing: border-box;
  background: #ffffff;
  border: 1px solid #efefef;
  border-radius: 16px;
  color: $secondary_color;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  cursor: pointer;

  &.small {
    height: 34px;
    border: 1px solid #efefef;
    border-radius: 8px;

    .btn {
      padding: 0 10px;
    }
  }

  &.withoutBorder {
    border: none;
  }

  &.open {
    box-shadow: 0 0 0 3px $hover_third_color;
  }

  &.disabled {
    cursor: not-allowed;
    color: $hover_secondary_color;
  }

  .btn {
    @include invisible-button;
    border-radius: 16px;
    padding: 0 21px 0 24px;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    text-align: left;
    cursor: pointer;
  }
}

.placeholder {
  color: $secondary_color;
  &.settedValue {
    position: absolute;
    top: -12px;
    background: #fff;
    left: 20px;
    font-size: 13px;
    padding: 0 5px;
  }
}

.content {
  overflow: hidden;
  position: relative;
  width: 100%;
  padding: 14px 17px 8px 4px;
  height: auto;
  max-height: 150px;
  background-color: #fff;
  border: 1px solid #efefef;
  border-radius: 16px;
  margin-right: 20px;
  right: 0;
  top: 0px;
  box-sizing: border-box;

  .wrapper {
    padding: 0 10px;
    overflow: auto;
    height: auto;
    max-height: 130px;
    @include scrollbar;
  }
  &.small {
    border-radius: 8px;
    padding: 14px 0 8px 0;

    .wrapper {
      padding: 0;

      .item {
        padding: 3px 10px;

        &:hover {
          background: $hover_third_color_primary;
        }

        &.selected {
          background: $third_color;
          color: #fff;
          font-weight: normal;
        }
      }
    }
  }
}

.item {
  cursor: pointer;
  padding: 3px 0;
  color: $secondary_color;
  &:hover {
    color: #000;
  }

  &.selected {
    text-decoration: underline;
    font-weight: 700;
  }
}

.arrow {
  position: absolute;
  right: 10px;
  height: 5.74px;
  width: 9.98px;
  top: 14px;

  &.open {
    transform: rotate(180deg);
  }
}

.value {
  width: calc(100% - 12px);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
