@import "styles/mixins.scss";
@import "styles/index.scss";

.root {
  position: relative;

  .icon {
    position: absolute;
    top: 16px;
    right: 20px;
    fill: #df2d2d;
    width: 20px;
    height: 20px;
  }
}

.input {
  @include input;
  width: 100%;
}

.passIcon,
.passIconClosed {
  position: absolute;
  right: 20px;
  top: 18px;
  cursor: pointer;
}

.passIconClosed {
  top: 15px;
}

.hidden {
  display: none;
}
