@import "styles/mixins.scss";

.root {
  width: 100%;
  position: relative;

  .input {
    box-sizing: border-box;
    padding: 7px 10px 6px 10px;
    background: #ffffff;
    border: 1px solid #efefef;
    border-radius: 8px;
    @include anyText(#000, 400, 14px, 21px);
    outline: none;
    width: 100%;
    height: 34px;
    &.errorContainer {
      border-color: $error_color;
    }
  }

  .error {
    position: absolute;
    right: 4px;
    top: 8px;
    width: 17px;
    height: 17px;
    border-radius: 50%;
    background: $error_color;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    @include anyText(#fff, 500, 12px, 21px);
    cursor: default;
  }
}
