.root {
  display: flex;
  align-items: center;
}

.icon {
  width: 24px;
  height: 24px;
}

.title {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #000;
  margin-left: 15px;
}
