@import "styles/mixins.scss";

.root {
  width: 100%;
  padding: 35px 20px;
  box-sizing: border-box;
  @include box;
  height: 280px;
  margin-top: 15px;
  position: relative;

  .title {
    @include anyText(#000, 600, 20px, 30px);
    margin-bottom: 15px;
  }

  .content {
    display: flex;
    align-items: center;
    justify-content: center;
    row-gap: 10px;
    width: 100%;
    row-gap: 24px;
    column-gap: 24px;
  }
}

.exportAllBtn {
  @include app_button;
  margin-left: 25px;
  margin-left: 20px;
  position: absolute;
  right: 0;
  top: -44px;
}

.loader {
  position: absolute;
  top: calc(50%);
  left: calc(50% - 20px);
  transform: translate(-50%, -50%);
  z-index: 1;
}
