@import "styles/index.scss";

.root {
  display: flex;
  flex-direction: column;
  .text {
    margin-bottom: 20px;
    color: $secondary_color;
  }
}
