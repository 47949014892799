$primary_color: #000000de;
$secondary_color: #6e7991;
$hover_secondary_color: rgba(110, 121, 145, 0.5);
$blue_color: #3f1fed;
$hover_blue_color: #534e70;
$site_color: #f8f8f8;
$third_color: #8bba05;
$hover_third_color: #a1d020a9;
$hover_third_color_primary: #a1d0202f;
$error_color: #ff0000;
$table_border_color: #e2e4e9;
$site_margin_bottom: 32px;
