@import "styles/mixins.scss";

.root {
  position: relative;

  display: grid;
  grid-template-columns: 12.57% 87.42%;

  height: 170px;
  margin-bottom: 30px;
  box-sizing: border-box;

  border: 1px solid $table_border_color;
  border-radius: 15px;

  background: #ffffff;

  .loaderContainer {
    position: absolute;
    top: calc(100% - 127px);
    left: calc(50% - 40px);
  }

  .tableHeaderColumn,
  .tableBodyColumn {
    display: grid;
    grid-template-rows: 70px 50px 50px;

    &:not(:last-child) {
      border-right: 1px solid $table_border_color;
    }

    div {
      display: flex;
      justify-content: center;
      align-items: center;

      @include anyText($secondary_color, 400, 20px, 30px);

      &:not(:last-child) {
        border-bottom: 1px solid $table_border_color;
      }
    }
  }

  .tableBody {
    display: grid;

    &.quarterlyTable {
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    }

    &.monthlyTable {
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;

      .tableBodyColumn div {
        @include anyText($secondary_color, 400, 18px, 27px);
      }
    }

    .tableBodyColumn div:not(:nth-child(3n + 1)) {
      @include anyText(#000, 500, 20px, 30px);
    }
  }
}

@media screen and (max-width: 1280px) {
  .root {
    .tableBodyColumn,
    .tableHeaderColumn {
      div {
        font-size: 18px;
      }
    }
  }
}

@media screen and (max-width: 1150px) {
  .root {
    .tableBodyColumn,
    .tableHeaderColumn {
      div {
        font-size: 17px;
      }
    }
  }
}

@media screen and (max-width: 1100px) {
  .root {
    .tableBodyColumn,
    .tableHeaderColumn {
      div {
        font-size: 16px;
      }
    }
    .tableBody {
      &.monthlyTable {
        .tableBodyColumn div {
          font-size: 16px;
        }
      }
    }
  }
}

@media screen and (max-width: 1050px) {
  .root {
    .tableBodyColumn,
    .tableHeaderColumn {
      div {
        font-size: 15px;
      }
    }
    .tableBody {
      &.monthlyTable {
        .tableBodyColumn div {
          font-size: 15px;
        }
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .root {
    .tableBodyColumn,
    .tableHeaderColumn {
      div {
        font-size: 14px;
      }
    }
    .tableBody {
      &.monthlyTable {
        .tableBodyColumn div {
          font-size: 14px;
        }
      }
    }
  }
}
